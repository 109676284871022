*{
  margin:0;
  padding:0;
}
 

_body .postit{
    margin: 20px auto;
    font-family: 'Reenie Beanie';
    text-align: justify;
    color:#fff;     
    font-size: 2em;
}

body .postit{
  margin: 20px auto;
  font-family: 'Julius Sans One', sans-serif;
  text-align: left;
  color: rgba(110,101,94,0.8);    
  font-size: 1.5em;
  }
span .postit{

  font-weight: normal;
  text-align: center;

}
.form-btn{
  background-color: "#9999";  
  opacity: 1;
  display:block;
  height:fit-content;
  width: fit-content;
  padding: 1em;
  box-shadow: 0 2px 3px rgba(129, 237, 67, 0.4), 0 6px 20px rgba(107, 237, 67, 0.5); 
  display: block;
  margin: 0 auto;   
  border-style: solid;
  border-width: 2px;
  border-color: rgb(107, 237, 67);
  border-radius: 10px;
}
.form-btn:hover{
  filter: brightness(0.5);
  cursor: pointer;  
}

  h2.postit {
    font-weight: bold;
    font-size: 2rem;
  }
  textarea{
    font-family: 'Lato';
    font-variant: small-caps;
  }
  p.postit{    
    display: block;  
    font-weight: normal;
    text-align: center;   
  }
  p.postit-inputs{
     
    display: block;  
    font-weight: normal;
    text-align: center;
    margin-left: 3em;
    margin-right: 3em;
    padding: 1em;
  }
   
  ul,li{
    list-style:none;
  }
  img .post-it{
    justify-content: center;
    width: inherit;
    height: auto;
    object-fit: cover;

  }
  .patchwork-img{
    position: relative;
    object-fit: fill;
    width: 100%;
  }
  .stamp{
    position: relative;  
    width: 60%;
  }
  ul{    
    justify-content: center;
  }
  ul li a{
    text-decoration:none;
    color:#000;
    
    
  }
  .wrapper{
    background:#ffc;
    height:10em;
    width:10em;
    padding:1em;
    box-shadow: 5px 5px 7px rgba(33,33,33,.7);
    transform: rotate(-6deg);
    display: inline-flex;
    flex-wrap: wrap;
    transition: transform .15s linear;
    margin: 1em;
  }
  .patchwork{  
    height:auto;
    width: 20em;
    transform: rotate(-6deg);
    display: inline-flex;
    flex-wrap: wrap;
    transition: transform .15s linear;
    margin: 2em;
  }
  .stamp-group{
    height:auto;
    width: 180px;
    height: 180px;
    transform: rotate(-6deg);
    display: inline-flex;
    position: relative;
    right: 0;
    top: -20px;
    transition: transform .15s linear;
    margin: 2em;
    justify-content: center;
    align-items: center;
    border: solid 1px black;
    border-radius: 50%;
  }
  .stamps-position{
    display: block;
    position: absolute;
    top: 0;
    left: 80%;
  }
  .h3title-group{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));

  }

  

  ul.postit li.postit{
    margin:1em;
  }
  img.postit img.postit{
    margin:1em;
  }
  .wrapper:nth-child(even){
    transform:rotate(4deg);
    position:relative;    
    top:5px;
    
  }
  .patchwork:nth-child(even){
    transform:rotate(4deg);
    position:relative;    
    top:5px;
   
  }
  .wrapper:nth-child(3n){
    transform:rotate(-3deg);
    position:relative;
    top:-5px;
    
  }
  .patchwork:nth-child(3n){
    transform:rotate(-3deg);
    position:relative;
    top:-5px;
    
  }
  .wrapper:nth-child(5n){
    transform:rotate(5deg);
    position:relative;
    top:-10px;
  }
  .patchwork:nth-child(5n){
    transform:rotate(5deg);
    position:relative;
    top:-10px;
  }

  .wrapper:hover,.wrapper:focus{
    box-shadow:10px 10px 7px rgba(0,0,0,.7);
    transform: scale(1.25);
    position:relative;
    z-index:5;
  }
  
  .patchwork:hover,.patchwork:focus{
    
    transform: scale(1.25);
    position:relative;
    z-index:5;
  }

  p.postit-inputs{     
    display: block;  
    font-weight: normal;
    text-align: center;
    margin-left: 1em;
    margin-right: 1em;
    padding: 1em;
  }

  @media screen and (max-width : 800px){
    .stamp-group{
      width: 100px;
      height: 100px;
    }
    .stamp{
      font-size: 0.7em;
    }
  }