@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Passions+Conflict&family=Poppins:wght@300&display=swap');

:root {
  --appBckgColor: rgba(245, 239, 230);
  --appBtn:rgba(247, 243, 237, 0.951);
  --appFamily: 'Montserrat', sans-serif;
  --title1H1Family: 'Parisienne';
  --title1Family: 'Supermercado One', cursive;
  --title2Family: 'Julius Sans One', sans-serif;
  --title1Color: #ED4353;
  --title2Color: #6A645A;
  --title3Color: rgb(76, 75, 70);
  --taupe: #A1A27E;
  --fontBold: 600;
  --fontLight: 400;
}
.centered-title{
  text-align: center;
  width: 80%;
  margin: 0 auto;
}

.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  background-color: rgba(245, 239, 230);
}

.patch-wrapper{
  position: relative;
  width: 100%;
  max-width: 100vw;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin-top: 2rem;
}

/* responsive iframe with a 16:9 aspect ratio
    thanks https://css-tricks.com/responsive-iframes/
*/
.yt-lite::after {
  content: "";
  display: block;
  padding-bottom: calc(100% / (16 / 9));
}
.yt-lite > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* play button */
.yt-lite > .lty-playbtn {
  width: 70px;
  height: 46px;
  background-color: #212121;
  z-index: 1;
  opacity: 0.8;
  border-radius: 14%; /* TODO: Consider replacing this with YT's actual svg. Eh. */
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.yt-lite:hover > .lty-playbtn {
  background-color: #f00;
  opacity: 1;
}
/* play button triangle */
.yt-lite > .lty-playbtn:before {
  content: '';
  border-style: solid;
  border-width: 11px 0 11px 19px;
  border-color: transparent transparent transparent #fff;
}

.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

/* Post-click styles */
.yt-lite.lyt-activated {
  cursor: unset;
}
.yt-lite.lyt-activated::before,
.yt-lite.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}
.full-article-container{
  width: 90%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  display: block;
  margin: 0 auto; 
  max-width: 100vw;
 

}
.cavasoft-logo{
  width: 150px;
  margin-left: 10px;
}
.cavasoft-wrapper{
  width: 90%;

}
.just-title{
text-align: justify;
color: var(--title2Color);
}
.passed-title{
  text-align: center;
  color: var(--title2Color);
  
  }
.just-dates{
  font-family: 'Julius Sans One', sans-serif; 
  color: #ED4353;
  font-weight: 700;
  text-align: justify;
}
.retro-dates{
  font-family: 'Julius Sans One', sans-serif; 
  color: #ED4353;
  font-weight: 700;
  text-align: center;
 
}
.pad-left-2-container{
  padding-left: 2em;
}
.ulysse-contest-img{
  width: 10em;
  height: 10em;
}
.coaching {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: left;
  align-items: center;
}
.a-contest{
  color: var(--title3Color);
}
.fb-page{
  padding-top: 2em;
}
.p-top-05-lh2{
  padding-top: 0.5em;
  line-height: 2em;
}
.coaching-img {
  height: 15em;
  width: auto;
  margin: 0 auto;
}

/*school */
.school-photo-block {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.stage-photo-block {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  position: relative;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
.cso-photo-block {
  display: flex;
  position: relative;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 5em;
}
.cso-latest-photo-block{
  display: flex;
  position: relative;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 5em;
 
}
.cso-cjt-img:nth-of-type(2n){
  position: relative;
  top: 4em;
}

.circle-text-wrapper{
  position: relative;
  display: block;
  height: 10em;
  width: 10em;
}
.cso-wrapper{
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
}

.lock-container{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  margin: 0 auto;
  width: 100%;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  perspective: 300px;
  perspective-origin: top;
  margin-bottom: 10em;
  justify-items: stretch;
}
.cross-container{
  display: flex;
  width: 100%;
  max-width: 100vw;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
}
.bonbel-block{
  
  position: relative;
  display: block;
  padding:0;
  width: 80%;
  margin: 0 auto;
  line-height: 2em;
  margin-bottom: 2em;

}
/*Copyright*/
.copyright-container{
  position: absolute;
  font-family: 'Passions Conflict';
  font-size: 2em;
  bottom: 0;
  display: block;
  margin: 0 auto;
  background-color:rgba(255,255,255,0.3);
  width: 100%;
  padding: 0;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
 
}
.copyright-container-global{
  position: relative;
  font-family: 'Passions Conflict';
  font-size: 2em;
  bottom: 0;
  display: block;
  margin: 0 auto;
  background-color:rgba(255,255,255,0.3);
  width: 100%;
  padding: 0;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.anim-lock-wrapper{
  left: 2em;
  position: relative;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; 
  -webkit-animation: 2.5s appear; 
          animation: 2.5s appear;  
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
  box-shadow: 8px 8px 50px rgba(39, 36, 2, 0.295);
}
.video-wrapper{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  margin-top: 2em;
}
.cross-video-wrapper{
  -webkit-clip-path: circle(50%);
          clip-path: circle(50%);
  position: relative;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;  

}
.cross-wrapper{  
  
  position: relative;
  display: flex;
  width: inherit;
  height: 150px; 
  
}

@-webkit-keyframes appear {
  0% {   
    opacity: 0;    
  }

 
  100% {
    opacity: 1; 
  }

}
@keyframes appear {
  0% {   
    opacity: 0;    
  }

 
  100% {
    opacity: 1; 
  }

}

.anim-lock-wrapper:nth-of-type(2n){  
  transform: translateZ(57px) translateY(91px) translateX(-49px) scale(0.9);
}
.anim-lock-wrapper:nth-of-type(3n){  
  transform: translateZ(3px) translateY(38px) translateX(-45px) scale(1.2);
}
.anim-lock-wrapper:nth-of-type(5n){  
  transform: translateZ(-44px) translateY(14px) translateX(-105px) scale(1.1);
}
.lock-wrapper{
  opacity: 0;
  left: 2em;
  position: relative;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: 8px 8px 50px rgba(39, 36, 2, 0.295);
}

.lock-wrapper:nth-of-type(2n){  
  transform: translateZ(50px) translateY(70px) translateX(-50px) scale(0.9);
}
.lock-wrapper:nth-of-type(3n){  
  transform: translateZ(3px) translateY(38px) translateX(-45px) scale(1.2);
}
.lock-picture{
  width: auto;
  height: 200px;
}
.cross-picture{
  width: inherit;
  height: 150px;
  -o-object-fit: contain;
     object-fit: contain;
}
.cso-pro-img{
  display: block;
  position: relative;
  -o-object-fit: cover;
     object-fit: cover;
  width: 75%;
  height: 25em;
  border-radius: 2%;
  margin: 0 auto;
  box-shadow: 4px 4px 10px rgba(39, 36, 2, 0.295);

}
.cso-medal-rel {
  display: block;
  position: relative;
  -o-object-fit: cover;
     object-fit: cover;
  width: 10em;
  height: 10em;
  -webkit-clip-path: circle(50%);
          clip-path: circle(50%);
  border-radius: 50%;
  margin: 0 auto;


}
.stage-medal-rel {
  display: block;
  position: relative;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100px;
  height: 100px;
  -webkit-clip-path: circle(50%);
          clip-path: circle(50%);
  border-radius: 50%;
  margin: 0 auto;

}
.cross-medal-rel {
  display: block;
  position: relative;
  -o-object-fit: cover;
     object-fit: cover;
  width: 10em;
  height: 10em;
  -webkit-clip-path: circle(50%);
          clip-path: circle(50%);
  border-radius: 50%;
  margin: 0 auto;

}
.cso-medal-rel:nth-child(even) {
  transform: scale(80%) translateY(10%) translateX(15%) rotate(-20deg);

}

.stage-medal-rel:nth-child(3n) {
  transform: scale(80%) translateY(10%) translateX(15%) rotate(-20deg);

}

.stage-medal-rel:nth-child(2n) {
  transform: scale(90%) translateY(100%) translateX(15%) rotate(20deg);
}

.stage-medal-rel:nth-child(even) {
  transform: scale(90%) translateY(15%) translateX(-5%) rotate(20deg);
}

.stage-medal-rel:nth-last-child() {
  transform: scale(100%) translateX(10%) rotate(20deg);
}

.school-pano-block {
  display: block;
  position: relative;
  width: inherit;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.school-photo-pano {
  display: block;
  position: relative;
  width: inherit;
  height: auto;
}

.school-medal-block {
  display: block;
  position: absolute;
  left: 70%;
  top: 60%;
  width: 20em;
  height: 20em;
  -webkit-clip-path: circle(50%);
          clip-path: circle(50%);
  border-radius: 50%;
}

.training-medal-block {
  display: block;
  position: relative;
  left: 10%;
  top: 10%;
  width: 20em;
  height: 20em;
  -webkit-clip-path: circle(50%);
          clip-path: circle(50%);
  border-radius: 50%;
}

.stage-medal-video {
  display: block;
  position: relative;
  -o-object-fit: cover;
     object-fit: cover;
  left: 75%;
  top: 60%;
  width: 15em;
  height: 15em;
  -webkit-clip-path: circle(50%);
          clip-path: circle(50%);
  border-radius: 50%;
}

.school-photo-medail {
  display: block;
  position: relative;
  width: inherit;
  height: inherit;

}

.info-school-block {
  margin-top: 5em;
}

/*fin school*/
.grecaptcha-badge {
  display: none;
}

ul,
li {
  font-family: 'Montserrat', sans-serif;
}

.info-title {
  font-family: 'Cinzel Decorative', cursive;
  /* color: #D7572B; */
  color: #ED4353;
  font-size: 1.6em;
  padding: 2em;
}

.logoEb-align {
  align-self: baseline;
}

.toile-cg {
  font-family: 'Cinzel Decorative', cursive;
  /* color: #D7572B; */
  color: #ED4353;
  font-size: 1em;
  padding: 0.5em;
}

.toile-cg-al {
  color: #6A645A;
  font-family: 'La Belle Aurore', cursive;
}

.toile-cg-ulysse {
  color: yellowgreen;
  padding: 0.5em;
  font-weight: 600;
  font-family: 'Julius Sans One', sans-serif;
}

.historique-wrapper {
  display: grid;
  grid-template-columns: 25% 75%;
}

.article-aside {
  display: block;
  text-align: justify;
}

span.article-hebergeur {
  display: inline-flex;
  font-family: 'Montserrat', sans-serif;

}

.article-hebergeur {
  display: block;
  text-align: justify;
  line-height: 1.5em;
}

.article-farwest {
  display: block;
  text-align: center;
  line-height: 2em;

}
.article-opendoors{
  display: block;
  text-align: center;
  line-height: 2em;
  width: 100%;
}

.far-west-title {
  font-family: 'Ewert';
  font-size: 1.5em;
  line-height: 2.5em;
}
.disney-title{
  font-family: 'Handlee', cursive;
  font-size: 1.8em;
  line-height: 2.5em;
  color: #ED4353;
}

.article-block {
  display: block;
  position: relative;
  width: 90%;
  height: auto;
  margin: 0 auto;
  padding-top: 1em;
  padding-bottom: 1em;
  max-width: 100vw;
}

.fit-img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer; 
  max-width: 100%;
  height: auto;
  margin: 0 auto; 
  opacity: 1; 
  display: inline-flex;
  flex-wrap: wrap;
  transition: transform .15s linear;
  background-color: var(--appBtn);
  margin: 0 auto;
  border-style: solid;
  border-width: 2px;
  border-color: var(--taupe);
  -webkit-animation: 4s scalepoplight;
          animation: 4s scalepoplight;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
  border-radius: 5%;
  
  
}

.training-image {
  width: inherit;
  height: 50%;
  display: grid;
  grid-template-columns: 50% 50%;

}

.training-block {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 3em;
  margin: 0 auto;
  position: relative;
  max-width: 100vw;
}
.challenges-block{
  display: flex;
    width: 100%;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    margin-top: 3em;
    margin: 0 auto;
    position: relative;
    max-width: 100vw;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.opendoors-block{
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0 auto;
  margin-top: 3em;
  position: relative;
  max-width: 100vw;
  align-items: center;
  justify-content: space-around;
  align-content: space-between;
  justify-items: center;
  margin-right: 2em;
}
.article-promo{
 display: flex;
 flex-direction: column;
}
.pony-banner{
  width: 100%;
  object-fit: contain;
  height: auto;
  position: relative;
  opacity: 0;
  transform: translateX(300px);
  transition: opacity 0.5s linear, transform 0.8s linear;

}
.pony-banner.active{
  width: 100%;
  object-fit: contain;
  height: auto;
  position: relative;
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.5s linear, transform 0.8s linear;
}
.article-promo-txt{
  line-height: 2em;
  width: 90%;
  margin: 0 auto;
  margin-right: 3em;
  margin-bottom: 2em;
}
.cso-cjt-img{
  -o-object-fit: contain;
     object-fit: contain;
  width: 45%;
  height: auto;
  border-radius: 3%;
  box-shadow: 4px 4px 10px rgb(39 36 2 / 30%);
}
.tarif-details{
  text-transform: none;
  color:#212121;
}
.justify-wrapper-mr2{
  text-align: justify;
  margin-right: 2em;
}
.justify-wrapper-mr2-ml2{
  text-align: justify;
  margin: 0 auto;
  margin-right: 2em;
  margin-left: 2em;
  width: 100%;
  position: relative;
  display: block;
  line-height: 1.5em;
}
.justify-wrapper#i{
  margin-top: 2em;
  font-style: italic;
}
.italic-style{
  font-style: italic;
  margin-top: 2em;
}
.justify-wrapper{
  line-height: 2em;
  width: 100%;
  display: block;
  position: relative;
  text-align: justify;
}
.cso-cjt-img-hidden{
opacity: 0;
}

.cso-label{
  color: grey;
  font-size: smaller;
  font-style: normal;
  font-variant: normal;
  text-transform: capitalize;
  
  
}
.cso-title-label{
  color: grey;
  font-size: smaller;
  font-style: normal;
  font-variant: normal;
  text-transform: capitalize;
  font-size: 1.25em;
  margin-bottom: 2em;
}
.cso-cjt{
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.training-block-child {
  background-color: #f8f8f1;
}

.training-block-child:nth-child(3n) {
  background-color: #e0dee0ed;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.training-block-child:nth-child(2) {
  background-color: #e0dee0ed;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.activity-list {
  text-align: center;
  line-height: 2.5em;
  text-transform: capitalize;
}

.article-center {
  display: block;
  text-align: center;
}

.installs-center {
  display: block;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  margin-top: 3rem;

}

.g-photos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  -moz-column-gap: 1em;
       column-gap: 1em;
  width: inherit;
  margin-top: 2em;
}

.aere {
  padding: 0.5em;
}
.h2-aere{
  margin-top: 1em;

}
.body-left {
  text-align: left;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.aere-left {
  text-align: left;
  padding: 0.5em;
  margin-top: 1em;
}

.aere-centered {
  text-align: center;
  padding: 0.5em;
}

.decale {
  padding: 0.5em;
  padding-left: 1em;
}

.article-wrapper {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 15% 60% 15%;
  -moz-column-gap: 5%;
       column-gap: 5%;
}

.article-full {
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 0.5em;
}

.article-photo {
  align-self: top;
  margin-right: 3rem;
  margin-left: 3rem;
  text-align: justify;
}

.body-historique {
  align-self: center;
  margin-right: 3rem;
  margin-left: 3rem;
  text-align: justify;
}

.body-article {
  align-self: center;
  text-align: justify;
}

.body-historique-span {
  margin: 0 auto;
  width: 95%;
  vertical-align: -webkit-baseline-middle;
  text-align: justify;

}

.photo-block {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  -moz-column-gap: 2%;
       column-gap: 2%;
  grid-row-gap: 2%;
  row-gap: 2%;
  margin-bottom: 2em;
  padding-left: 2em;
  align-items: center;
  justify-content: center;
  justify-items: stretch;
}

.photo-block-elt {

  display: block;
  width: 100%;
  height: 180px;
  -o-object-fit: contain;
     object-fit: contain;
}

.photo-blockactu-elt {

  display: block;
  width: 70%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}
.alaune-text{
  display: flex;
  margin-right: 2em;
}
.alaune-wrapper{
  position: relative;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  perspective: 2000px;
  margin-top: 2em;
}
.cheques-wrapper{
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  perspective: 2000px;
}
.cheque-elt{
  width: 60%;
  transform: rotateY(20deg);
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
  box-shadow: 8px 8px 50px rgba(39, 36, 2, 0.295);
}
.top-aere{
  margin-top: 1.8em;
}
.image-stage{
  width: 30%;
  transform: rotateY(30deg);
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
  box-shadow: 8px 8px 50px rgba(39, 36, 2, 0.295);
}
.photo-blockactu {

  display: block;
  width: 100%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}

.presentation-wrapper {
  display: grid;
  grid-template-columns: 80% 20%;
  margin: 0 auto;
  align-self: center;
  margin-top: 5rem;
}

.article-two-cols-bigleft {
  display: grid;
  grid-template-columns: 80% 20%;
  margin: 0 auto;
  align-self: center;
}

.article-two-cols {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 40% 40%;
  -moz-column-gap: 5%;
       column-gap: 5%;
  margin: 0 auto;
  align-self: center;
}

.article-two-cols-actu {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 60% 38%;
  -moz-column-gap: 2%;
       column-gap: 2%;
  margin: 0 auto;
  align-self: center;
}

.article-two-cols-bigright {
  display: grid;
  grid-template-columns: 20% 80%;
  margin: 0 auto;
  align-self: center;
}

.article-three-cols, .article-three-cols-simple {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  margin: 0 auto;
  align-self: center;
  

}
.article-three-cols{
  margin-top: 5rem;
}
.installs-rightblock {
  display: block;
}

.bordered-block {
  border-style: groove;
  margin: 2rem;
  padding: 1em;

}

.title-photo {
  display: block;
  width: 100%;
  height: auto;
}

.title-right {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
  align-self: center;
}

.bold-span {
  font-weight: 600;
}

.article-block-middle {
  display: block;
  width: 100%;
  height: auto;
  align-self: center;
  text-align: center;


}

.photo-title {
  width: 10em;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  margin-bottom: 1em;
  margin-top: 2em;
  cursor: pointer;
}
.photo-file{
  width: 10em;
  max-width: 100%;
  height: 14em;
  margin: 0 auto;
}
.photo-title-concours {
  width: 70vw;
  height: auto;
}

.photo-concours {
  width: auto;
  max-width: 100vw;
  height: 600px;
}

.arrowpop {
  -webkit-animation: 2s pop;
          animation: 2s pop;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
}

.btn-V {

  -webkit-animation: 2s scalepop;

          animation: 2s scalepop;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  transition-timing-function: cubic-bezier(.42, 0, .58, 1);
  background-color: "#9999";
  opacity: 1;
  display: block;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  padding: 1em;
  box-shadow: 0 2px 3px rgba(129, 237, 67, 0.4), 0 6px 20px rgba(107, 237, 67, 0.5);
  display: inline-flex;
  flex-wrap: wrap;
  transition: transform .15s linear;
  margin: 0 auto;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(107, 237, 67);

  border-radius: 50%;
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.btn-tarifs {
  background-color: "#9999";
  opacity: 1;
  display: block;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  padding: 1em;
  box-shadow: 0 2px 3px rgba(129, 237, 67, 0.4), 0 6px 20px rgba(107, 237, 67, 0.5);
  display: inline-flex;
  flex-wrap: wrap;
  transition: transform .15s linear;
  margin: 0 auto;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(107, 237, 67);  
  border-radius: 5%;
  cursor: pointer;
  width: inherit;
  padding: 1em;

}

.span-btn {
  color: #6A645A;
  text-decoration: none;
}

.signature-coloured {
  color: #6A645A;
  font-weight: 700;

}
.coaching-coloured{
  color: #6A645A;
  font-weight: 700;
  text-align:justify;
}
.span-V {
  font-size: 2em;
  color: rgba(110, 101, 94, 0.8);
}

.side-text:hover {
  color: yellowgreen;
}

@-webkit-keyframes pop {
  0% {
    transform: translateY(-50px);
    opacity: 0.5;
    border-color: rgba(107, 237, 67, 0.8);
    color: rgb(107, 237, 67);
  }

  50% {
    opacity: 1;
    transform: translateY(25px);
    border-color: rgba(107, 237, 67, 0.2);
    color: rgb(107, 237, 67);
  }

  100% {
    opacity: 0.5;
    transform: translateY(0px);
    color: rgba(107, 237, 67);
    border-color: rgba(107, 237, 67, 0.5);
  }

}

@keyframes pop {
  0% {
    transform: translateY(-50px);
    opacity: 0.5;
    border-color: rgba(107, 237, 67, 0.8);
    color: rgb(107, 237, 67);
  }

  50% {
    opacity: 1;
    transform: translateY(25px);
    border-color: rgba(107, 237, 67, 0.2);
    color: rgb(107, 237, 67);
  }

  100% {
    opacity: 0.5;
    transform: translateY(0px);
    color: rgba(107, 237, 67);
    border-color: rgba(107, 237, 67, 0.5);
  }

}

@-webkit-keyframes scalepop {
  0% {
    transform: scale(1);
    opacity: 0.5;
    border-color: rgba(107, 237, 67, 0.8);
    color: rgb(107, 237, 67);
  }

  50% {
    opacity: 1;
    transform: scale(1.5);
    border-color: rgba(107, 237, 67, 0.2);
    color: rgb(107, 237, 67);
  }

  100% {
    opacity: 0.5;
    transform: scale(1);
    color: rgba(107, 237, 67);
    border-color: rgba(107, 237, 67, 0.5);
  }

}

@keyframes scalepop {
  0% {
    transform: scale(1);
    opacity: 0.5;
    border-color: rgba(107, 237, 67, 0.8);
    color: rgb(107, 237, 67);
  }

  50% {
    opacity: 1;
    transform: scale(1.5);
    border-color: rgba(107, 237, 67, 0.2);
    color: rgb(107, 237, 67);
  }

  100% {
    opacity: 0.5;
    transform: scale(1);
    color: rgba(107, 237, 67);
    border-color: rgba(107, 237, 67, 0.5);
  }

}

@-webkit-keyframes scalepoplight {
  0% {
    transform: scale(1);
    opacity: 1;

    color: rgba(255, 217, 0, 1);
  }

  50% {
    opacity: 0.8;
    transform: scale(1.25);

    color: rgba(255, 217, 0, 1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
    color: rgba(255, 217, 0, 1);

  }

}

@keyframes scalepoplight {
  0% {
    transform: scale(1);
    opacity: 1;

    color: rgba(255, 217, 0, 1);
  }

  50% {
    opacity: 0.8;
    transform: scale(1.25);

    color: rgba(255, 217, 0, 1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
    color: rgba(255, 217, 0, 1);

  }

}

.img-article {
  padding: 0.5em;
  display: inline-flexbox;
  width: 100px;
  height: auto;
}

.article-inline {
  position: relative;
  width: 100%;
  grid-template-columns: 45% 45%;
  -moz-column-gap: 10%;
       column-gap: 10%;
}

.info-grid {
  display: grid;
  position: absolute;
  grid-template-columns: 75% 25%;
  width: 100%;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  grid-row-gap: 2rem;
  margin: 0 auto;
  margin-top: 10rem;
}

.relative-div-replace-absolute {
  display: block;
  position: relative;
  height: 20em;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.section-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

}

.custom-link {
  color: yellowgreen;
}

.elevage-sections {
  display: grid;
  grid-template-columns: 70% 22%;
  -moz-column-gap: 2%;
       column-gap: 2%;
  margin-right: 2em;

}

.portfolio-gallery {

  margin: 0 auto;
  margin-top: 1em;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  -moz-column-gap: 1em;
       column-gap: 1em;

}

.actu-blasons {
  margin: 0 auto;
  width: 60%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  -moz-column-gap: 1em;
       column-gap: 1em;

}

.actu-spectacle {
  margin: 0 auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));

}

.info-g {
  width: 95%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.medaillon {
  width: 50%;
}

.elevage-gallery {
  margin: 0 auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
  

}

.centered-video-wrapper {
  padding-top: 10em;
  text-align: center;
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
}

.end-block {
  display: inline-grid;
}

.portfolio-sections {
  display: grid;
  grid-template-columns: 70% 22%;
  -moz-column-gap: 2%;
       column-gap: 2%;
  margin-right: 2em;

}

.gallery-sections {
  display: block;
  width: 100%;
  margin: 0 auto;
  margin-right: 2em;
}

.portfolio-videos {
  margin: 0 auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;

}
.embed-div{
position: relative;
height: -webkit-fit-content;
height: -moz-fit-content;
height: fit-content;
}

.double-embed{
  position: relative;
  display: grid;
  grid-template-columns: 45% 45%;
  -moz-column-gap: 5%;
       column-gap: 5%;
  width: 100%;
  max-width: 100vw;
}
.embed-title{
  font-family: 'Nothing You Could Do', cursive;
  color: grey;
  padding: 0;
  margin: 0 auto;
  font-size: 1.8em;
  text-align: center;
  
}
.embed-title-div{
  margin-bottom: 2rem;
}
.embed-videos {
  width: 600px;
  height: 400px;
}

.embed-videos-gallery {
  width: 600px;
  height: 400px;
}
.deb-video{
  position: relative;
  display: block;
}
.loading-video-loading{
  position: relative;
  width: inherit;
  height: inherit;
  opacity: 0;
  transition: opacity 1s linear;
  z-index: -1;
 
}
.loading-video-loaded{
  position: relative;
  margin: 0 auto;
  display: flex;
  width: inherit;
  height: inherit;
  opacity: 1;
  transition: opacity 1s linear;
  z-index: -1;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
 
 
}
.loading-img-vidoe-loading{
  width: inherit;
  height: 100%;
  position: absolute;
  opacity: 1;
  z-index: 2;
  top: 0;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 1s linear;
 
}
.loading-img-video-loaded{
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index: 2;
  top: 0;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 1s linear;

}
.loading-video-wrapper{
  position: relative;
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

   
  
}
.videos-wrapper {
  display: block;
  width: inherit;
  height: auto;
}

.portfolio-video {
  display: inline-flex;
  position: relative;
  margin: 0.8em;
  margin-bottom: 2em;
}

h1 {
  font-family: 'Julius Sans One', sans-serif;
  color: #A1A27E;
  padding: 1em;

}

.h1-underline {
  border-style: none;
}

h2 {
  font-family: 'Julius Sans One', sans-serif;
  color: #A1A27E;
  font-size: 1.5em;
  font-variant: small-caps;
  padding: 1rem;
}

.galop-h2 {
  margin-top: 2em;
}

.p-m1-bottom {
  margin-bottom: 3em;
}

h2.big-h2 {
  font-size: 1.5em;
}

h3 span {
  color: #1f3f2f;
}

h3 {
  color: #ED4353;
  font-family: 'Julius Sans One', sans-serif;
  font-size: 1.5em;
  font-variant: small-caps;
  padding: 1rem;
}
h4 {
  color: #ED4353;
  font-family: 'Julius Sans One', sans-serif;
  font-size: 1em;
  font-variant: small-caps;
  padding: 1rem;
}
h4 span{
  color: var(--title3Color);
}
h5{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #A1A27E;
  font-size: 1em;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.title1 {
  font-family: 'Cinzel Decorative', cursive;
  /* color: #D7572B; */
  color: #ED4353;
  font-size: 1.6em;
}

.title2 {
  font-family: 'Julius Sans One', sans-serif;
  text-align: left;
  color: #A1A27E;
  padding-left: 1em;
  padding-top: 1em;
  font-weight: 600;
}

.title-elevage {
  font-family: 'Julius Sans One', sans-serif;
  text-align: center;
  color: #A1A27E;
  padding-left: 1em;
  padding-top: 1em;
  font-weight: 600;
}

.title-elevage span {
  color: #ED4353;
}

.title-mentions {
  font-family: 'Julius Sans One', sans-serif;
  text-align: center;
  color: #A1A27E;
  padding-top: 5rem;
  font-weight: 600;
}

.title2-padding {
  font-family: 'Julius Sans One', sans-serif;
  text-align: left;
  /* color: #F1895C; */
  /*  color: #ED4353; */
  color: #A1A27E;
  padding: 1em;
}

.dates {
  font-family: 'Julius Sans One', sans-serif;
  padding-left: 3em;
  color: #ED4353;
  font-weight: 700;
}

.dates-centered {
  font-family: 'Julius Sans One', sans-serif;
  text-align: center;
  font-weight: 700;
}

.dates span {
  color: #A1A27E;
  font-weight: 600;
}

.title2-paddingplus {
  font-family: 'Julius Sans One', sans-serif;
  text-align: left;
  color: #A1A27E;
  padding-left: 3em;

}

.align-left {
  text-align: left;
  padding-left: 1em;
  padding-bottom: 0.5em;
}

.barre {
  text-decoration: line-through;
  color: #A1A27E;
}


.title3 {
  font-family: 'Julius Sans One', sans-serif;
  color: #A1A27E;
  text-align: center;


}

h2.title3 {
  font-family: 'Julius Sans One', sans-serif;
  color: #A1A27E;
  text-align: center;
  font-size: 2em;
}

h2.title-elevage {
  font-family: 'Julius Sans One', sans-serif;
  color: #A1A27E;
  text-align: center;
  font-size: 2em;
}

.title3-blck {
  font-family: 'Julius Sans One', sans-serif;
  color: #A1A27E;
  text-align: center;
  font-weight: 900;
  padding-top: 3em;
  padding-bottom: 2em;
}

.title6 {
  font-family: 'Julius Sans One', sans-serif;
  color: #A1A27E;
  text-align: center;
  font-weight: 900;
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 1em;
}

.title5 {
  font-family: 'Julius Sans One', sans-serif;
  color: #A1A27E;
  text-align: center;
  font-weight: 900;
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 2em;
}

h2 .title4 {
  font-family: 'Cinzel Decorative', cursive;
  color: #172c05;
  margin: 0 auto;
  width: 100%;
  text-align: center;

}


.subTitle2 {
  font-family: 'Nothing You Could Do', cursive;
  color: grey;
  padding: 0;
  margin: 0 auto;
  font-size: larger;
  text-align: center;
  padding-top: 2rem;
}

.subTitle-footer {
  color: white;

}

.domain {
  position: absolute;

}

.fullcarousel-wrapper {
  display: block;
  position: relative;
  width: 100%;
}

.img-carousel {
  position: relative;
  height: 100%;
  max-width: 100%;
  -o-object-fit: fill;
     object-fit: fill;

}

.img-simple-carousel {
  position: relative;
  max-width: 100%;
  height: inherit;
  -o-object-fit: contain;
     object-fit: contain;
}

.subtitle-accueil {
  text-align: left;
}

.subtitle-concours {
  text-align: center;
}

.cso-article {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.cso-contest{
  margin-top: 2rem;
    margin-bottom: 1rem;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}

.retro-container {
  margin: 0 auto;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: grid;
  position: relative;
  grid-template-columns: 20% 60% 20%;
  max-width: 100vw;
  margin-bottom: 1rem;
  margin-top: 2em;
}
.retro-wrapper {
  display: block;
  text-align: center;
  margin: 0;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 5rem;
}
.img-small-inline{
  width: 50px;
  height: auto;
}
.flex-inline{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}
.border-retro-title {
  height: 30px;
  width: 100%;
  border-bottom: 2px solid #6A645A;
  
}

.border-retro-bottom {
  height: 30px;
  width: 70%;
  margin: 0 auto;
  display: block;
  position: relative;
  border-bottom: 2px solid #6A645A;
  margin-bottom: 1em;
}

h2.retro-title {
  text-align: center;
  font-size: 1.7em;
}
.actu-section-title{
  text-align: center;
  color: var(--title3Color);
}

.side-img-container {
  display: flex;
    width: 100%;
    position: relative;
    background: rgb(110, 101, 94);
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: space-between;
    align-items: center;
    height: inherit;


}

.side-img-container-actu {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;

}
.img-side-concours{
  width: 100%;
  height: 100%;
  position : relative;
  display: flex; 
  -o-object-fit: cover; 
     object-fit: cover;
}
.img-side {
  width: 100%;
  position : relative;
  display: flex;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.center-img-container {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
}

.img-center {
  width: 100%;
  position: relative;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  /* clip-path: polygon(0 0, 100% 0%, 100% 75%, 0 100%); */
}

.img-center-fullheight {
  width: 100%;
  position: relative;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.img-center-pano{
  width: 100%;
  position: relative;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.img-center-cut {
  width: 100%;
  position: relative;
  height: 8em;
  -o-object-fit: cover;
     object-fit: cover;
}

.article-small {
  width: 60%;
  height: auto;
  position: relative;
  display: block;
  margin: 0 auto;
}

.img-clip-left {
  width: 100%;
  position: relative;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-clip-path: polygon(35% 0, 100% 0%, 100% 100%, 0 100%);
          clip-path: polygon(35% 0, 100% 0%, 100% 100%, 0 100%);
}

.title-integration {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5%, 1fr));
}

.title-title-integration {
  position: relative;

}

.title-integration-leftside {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
  padding-left: 1em;
}

.img-center-alone {
  width: 80%;
  margin-top: 2em;
  position: relative;
  height: 20%;
  -o-object-fit: scale-down;
     object-fit: scale-down;
}

.img-bandeau {
  width: 100%;
  margin-top: 2em;
  position: relative;
  height: 10em;
  -o-object-fit: cover;
     object-fit: cover;
}

.chouchou-team {
  position: relative;


}

.img-compass {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  -o-object-fit: scale-down;
     object-fit: scale-down;
  z-index: 1000;
}

.img-foranim {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  -o-object-fit: scale-down;
     object-fit: scale-down;
  z-index: 1000;
}

.img-compass:hover,
.img-compass:active {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  cursor: pointer;

}

.bckg-compass {
  position: absolute;
  -o-object-fit: scale-down;
     object-fit: scale-down;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.compass-btn {
  border-style: none;
  position: relative;
  z-index: 1000;
  width: 100%;
  height: 100%;

}

.img-btn {
  border-style: none;
  position: relative;
  z-index: 1000;
  width: 100%;
  height: 100%;

}

.compass-btn-open {
  border-style: none;
  position: relative;
  z-index: 1000;

}

.compass-btn-open:hover {
  cursor: pointer;
}

.compass-btn :hover {
  cursor: pointer;
}

.section-details {
  position: relative;
  z-index: 5000;
}

/* .info-block{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5000;
} */



.about-h1 {
  font-family: 'Julius Sans One', sans-serif;
  color: #A1A27E;
  padding: 0.8em;
  font-weight: 600;
  font-size: 1em;
}

.about-sections {
  position: relative;
  text-align: center;
  display: block;
  width: inherit;
  margin: 0 auto;
  /* background-color: #EED7C5; */

}

._about-sections {

  display: grid;
  grid-template-columns: 22% 22% 22% 20%;
  -moz-column-gap: 2%;
       column-gap: 2%;
  width: inherit;
  margin: 0 auto;
  /* background-color: #EED7C5; */

}

.img_position {
  position: relative;
}

.center-div {
  display: block;
  margin: 0 5em;
}

.center-elts {
  display: block;
  margin: 0 auto;

  width: 80%;
  text-align: center;
  align-self: center;
}

.title-div {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  margin: 0 auto;
  text-align: -webkit-center;
  text-align: center;
  align-items: self-start;
}

.div-for-grid {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.bckg-position {
  height: 50em;
}

.fixed-bckg-00 {
  width: 100%;
  height: 100vh;
  background-image: url("../images/accueil/carriere/carriere_01.jpg");

  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  opacity: 1;
  margin: 0 auto;
  background-color: rgba(245, 239, 230);
  /* background-color: #EED7C5; */
  background-attachment: fixed;
}

.fixed-bckg-compo {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  opacity: 1;
  margin: 0 auto;
  background-color: rgba(245, 239, 230, 0.5);
  /* background-color: #EED7C5; */
  position: fixed;

}

.fixed-bckg-01 {
  width: 100%;
  height: 100vh;
  background-image: url("../images/background04.jpg");
  background-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  opacity: 1;
  margin: 0 auto;
  background-color: rgba(245, 239, 230);
  /* background-color: #EED7C5; */
  background-attachment: fixed;
}

.fixed-bckg-02 {
  width: 100%;
  height: 100vh;
  background-image: url("../images/background07.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  margin: 0 auto;
  /* background-color: #EED7C5; */
  background-attachment: fixed;
}

.fixed-bckg-03 {
  width: 100%;
  height: 100vh;
  background-image: url("../images/background08.jpg");
  background-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  opacity: 0.8;
  margin: 0 auto;
  background-color: rgba(245, 239, 230);
  /* background-color: #EED7C5; */
  background-attachment: fixed;
}

.fixed-bckg-04 {
  width: 100%;
  height: 100vh;
  background-image: url("../images/background03.jpg");
  background-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  opacity: 0.8;
  margin: 0 auto;
  background-color: rgba(245, 239, 230);
  /* background-color: #EED7C5; */
  background-attachment: fixed;
}

.fixed-bckg-05 {
  width: 100%;
  height: 100vh;
  background-image: url("../images/background02.jpg");
  background-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  opacity: 0.8;
  margin: 0 auto;
  background-color: rgba(245, 239, 230);
  /* background-color: #EED7C5; */
  background-attachment: fixed;
}

.fixed-bckg-06 {
  width: 100%;
  height: 100vh;
  background-image: url("../images/background03.jpg");
  background-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  opacity: 0.8;
  margin: 0 auto;
  background-color: rgba(245, 239, 230);
  /* background-color: #EED7C5; */
  background-attachment: fixed;
}

.fixed-bckg-07 {
  width: 100%;
  height: 100vh;
  background-image: url("../images/background05.jpg");
  background-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  opacity: 0.8;
  margin: 0 auto;
  background-color: rgba(245, 239, 230);
  /* background-color: #EED7C5; */
  background-attachment: fixed;
}

.fixed-bckg-08 {
  width: 100%;
  height: 100vh;
  background-image: url("../images/background06.jpg");

  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  margin: 0 auto;
  background-color: rgba(245, 239, 230);
  /* background-color: #EED7C5; */
  background-attachment: fixed;
}

.fixed-bckg-09 {
  width: 100%;
  height: 100vh;
  background-image: url("../images/background09.jpg");
  background-blend-mode: luminosity;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  opacity: 0.8;
  margin: 0 auto;
  background-color: rgba(245, 239, 230);
  /* background-color: #EED7C5; */
  background-attachment: fixed;
}

.about-section-img {
  width: 100%;

  z-index: 1000;
  -o-object-fit: scale-down;
     object-fit: scale-down;

}

.about-section {
  width: inherit;
  height: inherit;
  display: inline-block;
  margin: 0 auto;

}

.compass-div {
  display: block;
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  align-self: center;
  text-align: center;
}

div.compass {
  width: 100%;
  height: 100%;

}

.sections-wrapper {
  display: block;

}

.swipe-btn {
  width: 100%;
  height: auto;
  border-style: none;
}

.swipe-btn:active .swipe-btn:hover .swipe-btn:focus {
  border-style: none;
}

.theCarousel {
  position: relative;
  width: 100%;
  height: 100%;
}

.thecarouselnav {
  position: relative;
  z-index: 1000;
  display: -ms-inline-flexbox;
  padding-top: 2em;

}

.carousel-arrow {
  font-size: xx-large;
  color: rgba(245, 239, 230, 0.8);

  width: 100%;

}

.column-div {
  display: grid;
  grid-template-columns: 30% 45%;
  -moz-column-gap: 10%;
       column-gap: 10%;
  text-align: justify;
}

.arrow-div {
  background-color: rgba(20, 20, 20, 0.8);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
}

.carouselgrid {
  display: block;
}

.theweb {
  display: grid;
  grid-template-rows: 30% 70%;
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
}

.div-middle {
  align-self: center;
  width: 100%;
}

.div-horaires {
  margin: 0 auto;
  color: gold;
  width: 50%;
  padding: 3rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: rgba(33, 33, 33, .8);

}

.signature {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  padding-top: 3rem;
}

.carousel-wrapper {
  display: grid;
  grid-template-rows: 80% 20%;
  position: relative;
  height: inherit;
  width: 100%;
}

.simplecarousel-wrapper {
  display: block;
  height: 500px;
  margin-bottom: 10rem;
  width: 100%;
}

.div-rowauto {
  display: block;

}

.carouselnav-wrapper {
  display: block;
  position: relative;
  padding: 1rem;

}

.gmap {
  width: 100%;
  height: inherit;
  margin: 0 auto;
}

.gmap-div {
  width: 60%;
  display: block;
  margin: 0 auto;
  margin-top: 2rem;
  border-style: ridge;
}

.nav-btn {
  border-style: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.btn-test {
  z-index: 500000;
}

.btn-test:hover {
  cursor: pointer;
}

button {
  background-color: rgba(0, 0, 0, 0);
}

.route-section {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  width: 100%;

  margin-right: 2em;

}

.align-proj-section {
  padding: 5em;
}

.invisible-title {
  color: rgba(0, 0, 0, 0);

}

.section-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-style: none;


}

.section-button:focus {
  border-style: none;
  border-color: rgba(0, 0, 0, 0);
  outline: none;
}

@-webkit-keyframes slideIn {
  0% {

    transform: translateX(-100%);
  }


  100% {

    transform: translateX(0%);
  }

}

@keyframes slideIn {
  0% {

    transform: translateX(-100%);
  }


  100% {

    transform: translateX(0%);
  }

}

.wrapper {
  background-color: indianred;
  opacity: 0.5;
  width: 700px;
  height: 300px;
  margin: 20px;
  text-align: center;
  vertical-align: middle;

}

.dev-card {
  background-color: "#9999";
  opacity: 1;
  display: block;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  padding: 1em;
  box-shadow: 0 2px 3px rgba(129, 237, 67, 0.4), 0 6px 20px rgba(107, 237, 67, 0.5);
  display: inline-flex;
  flex-wrap: wrap;
  transition: transform .15s linear;
  margin: 0 auto;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(107, 237, 67);
  border-radius: 20px 10px 10px;
}

._dev-card {
  background: #c8c9a8bb;
  opacity: 1;
  display: block;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 10em;
  padding: 1em;
  box-shadow: 5px 5px 7px rgba(33, 33, 33, .7);
  transform: rotate(-2deg);
  display: inline-flex;
  flex-wrap: wrap;
  transition: transform .15s linear;
  margin: 0 auto;
  border-style: solid;
  border-color: ivory;
  border-radius: 20px 40px 10px;
}

.dev-card:nth-child(even) {

  position: relative;
  top: 5px;
}

.dev-card:hover,
.dev-card:focus {
  box-shadow: 0 1px 2px rgba(67, 237, 67, 0.7), 0 3px 10px rgba(73, 237, 67, 0.8);
  transform: scaleY(1.2);
  position: relative;
  z-index: 5;

}

._dev-card:hover,
.dev-card:focus {
  box-shadow: 0 1px 2px rgba(67, 237, 67, 0.7), 0 3px 10px rgba(73, 237, 67, 0.8);
  transform: scale(1.2);
  position: relative;
  z-index: 5;

}


.img-dev-card {
  width: 100%;
  height: auto;
}

.img-dev-card:hover,
.img-dev-card:focus {
  -webkit-filter: brightness(1.10);
          filter: brightness(1.10);
  cursor: pointer;
}

.dev-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
}

.bienvenue-center {
  display: block;
  margin: 0 auto;
  padding-top: 5rem;
  width: 100%;
  height: 100%;
}

.bienvenue-wrapper {
  margin: 0 auto;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
}

.boutique-wrapper {
  margin: 0 auto;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
}

a.link-no-underline {
  text-decoration: none;
  display: contents;
}

.photo-article-boutique {
  width: 150px;

  height: auto;
  margin: 0 auto;
  margin-bottom: 1em;
  margin-right: 1em;
  margin-top: 2em;
  cursor: pointer;
}

.bienvenue-img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.img-block {
  width: auto;
  height: 15em;
  display: inline-flex;
  position: relative;
  margin: 0 auto;
}

.affiche-rentree {
  width: 30em;
  max-width: 70vw;
  display: inline-flex;
  position: relative;
  margin: 0 auto;
}

.centered-img {
  width: 60%;
  height: auto;
  margin: 0 auto;
  padding: 1em;
  display: block;
}

.article-img {
  display: block;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  position: relative;
  margin-top: 2rem;
}

.article-img-inline {
  display: inline-flex;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  position: relative;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin-top: 2em;
  -o-object-fit: contain;
     object-fit: contain;

}

.article-img-alternate {
  display: inline-flex;
  text-align: center;
  width: auto;
  margin: 0 auto;
  position: relative;
  height: 350px;
  max-width: 100%;
  padding: 1em;
  margin-top: 2em;
  -o-object-fit: contain;
     object-fit: contain;

}

.inline-title {
  display: inline-block;
  text-align: justify;
  position: relative;
  color: #6A645A;
  font-weight: 700;
  padding: 1em;
}

.title-article {
  display: block;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.btn-goToProjectwrapper {
  background-color: rgba(20, 20, 20, 0.8);
  border-radius: 5px;
  padding: 1em;
  width: 20em;
  margin: 0 auto;
  margin-top: 2em;

}

.btn-gotoProject {
  box-shadow: 5px 5px 7px rgba(33, 33, 33, .7);
  border-radius: 6px;
  background-color: rgba(200, 200, 200, 0.8);
  border-style: none;
  cursor: pointer;
  padding: 1em;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  width: inherit;
}

.brochure-block {
  display: inline-grid;
  text-align: center;
  align-items: center;
  justify-items: center;
  margin-bottom: 2em;
}

.wrapper-details {
  display: grid;
  grid-template-columns: 15% 15% 15% 15% 15%;
  -moz-column-gap: 5%;
       column-gap: 5%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  text-align: center;

}

.p-align-left {
  text-align: left;
  padding: 1em;
}

.p-centered {
  text-align: center;
  padding: 1em;
}

.article-imgs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  -moz-column-gap: 2em;
       column-gap: 2em;
  row-gap: 2rem;
}

.wrapper-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 4rem;
  width: 100%;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.card-details {
  text-align: center;
  font-size: 0.8em;
}

.btn-gotoProject:hover,
.btn-gotoProject:active {
  -webkit-filter: brightness(1.25);
          filter: brightness(1.25);
  transform: scale(1.15);
}

.btn-goToProjectwrapper:hover,
.btn-goToProjectwrapper:active {
  -webkit-filter: brightness(1.25);
          filter: brightness(1.25);
  transform: scale(1.05);
}

.project-p {
  margin: 1em;
}

.inv-btn-gotoproj {
  width: 0;
  height: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgba(245, 239, 230, 0.8);

}

.info-div {}

.infosection-header {
  display: grid;
  grid-template-columns: 20% 70%;
  -moz-column-gap: 10%;
       column-gap: 10%;
}

.infosection-wrapper {
  display: block;
  text-align: left;

}

.footer-wrapper {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 2px;
  padding-top: 2rem;
  margin-top: 2rem;
  margin-bottom: 5rem;
  background-color: rgb(110, 101, 94);
}

.footer-block {

  display: block;
  background-color: rgb(110, 101, 94);
  color: gold;
  font-family: 'Nothing You Could Do', cursive;

  margin: 0 auto;
  margin-top: 10rem;
  width: 70%;
  font-size: larger;
}

.App-link {
  color: #61dafb;
}


.invisiblebutton {
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  -o-border-image: none;
     border-image: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.noborderbutton {

  border-color: rgba(0, 0, 0, 0);

}

.noborderbutton:disabled {
  background-color: red;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.invisiblebutton:focus {
  background-color: red;
  border-style: none;
  border-color: white;
  -o-border-image: none;
     border-image: none;
  box-shadow: white;
  transform: scale(2);
}

.invisiblebutton:hover {
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  -o-border-image: none;
     border-image: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

/*SCROLLBAR*/


body::-webkit-scrollbar {
  width: 10px;
  /* width of the entire scrollbar */

}

body::-webkit-scrollbar-track {
  background: #6A645A;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {

  /* color of the scroll thumb */
  background-image: -webkit-linear-gradient(top,
      #2f2c09 0%,
      #bba803 100%);
  /* border-radius: 5px; */
  /* roundness of the scroll thumb */
  border: 3px solid #6A645A;
  /* creates padding around scroll thumb */
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);  */

}

::-webkit-scrollbar-track-piece {
  /*after trackbar*/
  height: 60%;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-image: -webkit-linear-gradient(top,
      #bba803 0%,
      #f8fc2d 100%);
}

/*Firefox*/
body {
  overflow-x: hidden;
  scrollbar-width: thin;
  /* "auto" or "thin" */
  scrollbar-color: #2ef1ff #ace7ff;
  /* scroll thumb and track */
}

.logo-business-div {
  /* background-color: rgba(106, 100, 90, 1); */
  background-color: rgba(245, 239, 230);
  border-color: rgb(237, 67, 83);
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 2px 3px rgba(237, 67, 83, 0.4), 0 6px 20px rgba(237, 67, 83, 0.5);
}

.logo-business-img {
  -webkit-filter: grayscale();
          filter: grayscale();
  -webkit-filter: sepia();
          filter: sepia();
  height: 5vw;
  width: auto;
  display: inline-flex;
  padding: 0.5em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.open_contact {
  font-size: 10em;
  text-align: center;
  border-style: solid;
  color: #ED4353;
}

.contact-section {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 50rem;
}

.contact-label {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.8em;
  padding: 0.5em;

}

p.contact-txtarea {

  text-align: left;
}

.contact-input {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.8em;
  background-color: rgba(245, 239, 230);
  border-radius: 5px;
  border-style: solid;
  border-color: darkseagreen;
  padding: 2px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 10em;
}

/* IPhone */
@media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2) {
  .stage-medal-rel{
    width: 60px;
    height:60px;
  }
  
  .fit-img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer; 
    max-width: 100%;
    height: auto;
    margin: 0 auto; 
    opacity: 1; 
    display: inline-flex;
    flex-wrap: wrap;
    transition: transform .15s linear;
    background-color: var(--appBtn);
    margin: 0 auto;
    border-style: solid;
    border-width: 2px;
    border-color: var(--taupe);
    -webkit-animation: 4s scalepoplight;
            animation: 4s scalepoplight;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    transition-timing-function: cubic-bezier(.42, 0, .58, 1);
    border-radius: 5%;
    
    
  }
  
  .cso-cjt-img{
    -o-object-fit: contain;
       object-fit: contain;
    width: 45%;
    height: auto;
    border-radius: 3%;
    box-shadow: 4px 4px 10px rgb(39 36 2 / 30%);
  }
  
  .retro-container {
    margin: 0 auto;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: grid;
    position: relative;
    grid-template-columns: 20% 60% 20%;
    max-width: 100vw;
    margin-bottom: 1rem;
  }
  .cso-cjt-img{
    width: 80%;
  }
  .cso-pro-img{
    top:0em;
  }
  .image-stage{
    width: 60%;
  }
  .cross-picture{
    width: inherit;
    height: inherit;
    -o-object-fit: cover;
       object-fit: cover;
  }
  
  .cross-container{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px) 110px;
    grid-template-rows: auto;
    row-gap: 1em;
  }
  
  .copyright-container{
   font-size: 1em;   
  }
  .lock-picture{
    width: auto;
    height: 100px;
  }
  .double-embed{
    position: relative;
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 50% 50%;
    width: 100%;
    max-width: 100vw;
    margin: 1rem;
  }
  .opendoors-block{
    display: block;
  }
  
  .school-medal-block {
    width: 10em;
    height: 10em;
    top: 65%;
    left: 50%;
  }

  .actu-spectacle {
    margin: 0 auto;
    width: 100%;
    display: block;
    text-align: -webkit-center;

  }

  .actu-blasons {
    margin: 0 auto;
    width: 100%;
  }

  .medaillon {
    width: 0%;
  }

  .photo-block-elt {
    display: block;
    width: 100%;
    height: 80px;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .article-three-cols, .article-three-cols-simple {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: stretch;
    flex-wrap: wrap;

  }
  .article-three-cols{
    margin-top: 1rem;
  }
  .section-wrapper {
    width: 100%;
  }

  .elevage-sections {
    display: block;
    -moz-column-gap: 2%;
         column-gap: 2%;
    margin-right: 2em;

  }

  /*   .elevage-gallery{ 
        display: block;
      } */
  .article-wrapper {
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  .article-img-inline {
    display: block;
    width: 100%;
    -o-object-fit: contain;
       object-fit: contain;

  }

  .portfolio-sections {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 70% 20%;
    row-gap: 10%;
    margin-right: 2em;

  }

  .title2 {
    text-align: center;
    font-size: 1.5em;
  }

  .carousel-wrapper {
    height: 35vh;
  }

  .bckg-position {
    height: 85em;
  }

  .historique-wrapper {
    display: block
  }

  .presentation-wrapper {
    display: block;

  }


  h2 {
    font-size: 1.2em;
  }

  h1 {
    font-size: 2em;

  }

  .h1-section {
    font-size: 0.8em;
    font-family: 'Julius Sans One', sans-serif;
    color: #A1A27E;
    padding: 1em;
    font-weight: 700;
  }

  .about-h1 {
    font-size: 0.8em;
  }

  .h1-section {
    font-size: 1.5em;
  }

  .title1 {
    text-align: center;
    font-size: 1.6em;
  }

  .theCarousel {
    height: inherit;
  }

  .dev-wrapper {
    display: block;
  }

  .dev-card {
    max-width: 70%;
  }

  .dev-card:nth-child(even) {
    transform: none;
    position: relative;
    top: 5px;
  }

  .card-wrapper {
    margin-top: 5em;
  }

  .wrapper-details {
    display: grid;

    grid-template-columns: none;
    row-gap: 5%;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    position: relative;
    text-align: center;
  }

  .route-section {
    font-size: smaller;

  }

  .portfolio-sections {
    display: grid;
    grid-template-columns: 70% 22%;
    -moz-column-gap: 2%;
         column-gap: 2%;
    margin-right: 2em;

  }

  .embed-videos {
    width: 90%;
    height: 150px;
  }

  .embed-videos-block {
    display: block;
    width: 100%;
    height: auto;
  }

  .portfolio-videos {
    margin: 0 auto;
    display: block;
    width: 100%;
    height: auto;
    margin-top: 1em;
  }

  .info-grid {
    display: block;
    position: absolute;
    width: 80%;
    margin: 5em auto;
  }

  .relative-div-replace-absolute {
    display: block;
    position: relative;
    height: 22em;

  }

  .compass-div {
    display: grid;
    position: relative;
    margin: 0 auto;
    width: 100%;


  }

  .compass-phone {
    margin-left: 5em;
  }

  .fixed-bckg-00 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/accueil/carriere/carriere_01.jpg");

    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-compo {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230, 0.5);
    /* background-color: #EED7C5; */
    position: fixed;

  }

  .fixed-bckg-01 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background04.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-02 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background07.jpg");

    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;

    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-03 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background08.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-04 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background03.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-05 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background02.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-06 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background03.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-07 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background05.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-08 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background06.jpg");

    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-09 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background09.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

}

/*IPHONE 5*/
@media screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) {
  .stage-medal-rel{
    width: 60px;
    height:60px;
  }
  .fit-img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer; 
    max-width: 100%;
    height: auto;
    margin: 0 auto; 
    opacity: 1; 
    display: inline-flex;
    flex-wrap: wrap;
    transition: transform .15s linear;
    background-color: var(--appBtn);
    margin: 0 auto;
    border-style: solid;
    border-width: 2px;
    border-color: var(--taupe);
    -webkit-animation: 4s scalepoplight;
            animation: 4s scalepoplight;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    transition-timing-function: cubic-bezier(.42, 0, .58, 1);
    border-radius: 5%;
    
    
  }
  
  .cso-cjt-img{
    -o-object-fit: contain;
       object-fit: contain;
    width: 45%;
    height: auto;
    border-radius: 3%;
    box-shadow: 4px 4px 10px rgb(39 36 2 / 30%);
  }
  
  .retro-container {
    margin: 0 auto;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: grid;
    position: relative;
    grid-template-columns: 20% 60% 20%;
    max-width: 100vw;
    margin-bottom: 1rem;
  }
  
  .cso-cjt-img{
    width: 80%;
  }
  .cso-pro-img{
    top:0em;
  }
  .image-stage{
    width: 60%;
  }
  .cross-picture{
    width: inherit;
    height: inherit;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .cross-container{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px) 110px;
    grid-template-rows: -webkit-max-content;
    grid-template-rows: max-content;
  }
  
  .copyright-container{
    font-size: 1em;   
   }
  .lock-picture{
    width: auto;
    height: 100px;
  }
  .double-embed{
    position: relative;
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 50% 50%;
    width: 100%;
    max-width: 100vw;
    margin: 1rem;
  }
  
  .opendoors-block{
    display: block;
  }
  .school-medal-block {
    width: 10em;
    height: 10em;
    top: 65%;
    left: 50%;
  }

  .actu-spectacle {
    margin: 0 auto;
    width: 100%;
    display: block;
    text-align: -webkit-center;

  }

  .actu-blasons {
    margin: 0 auto;
    width: 100%;
  }

  .medaillon {
    width: 0%;
  }

  .photo-block-elt {
    display: block;
    width: 100%;
    height: 80px;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .article-three-cols, .article-three-cols-simple {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: stretch;
    flex-wrap: wrap;

  }
  .article-three-cols{
    margin-top: 1rem;
  }

  .section-wrapper {
    width: 100%;
  }

  .elevage-sections {
    display: block;
    -moz-column-gap: 2%;
         column-gap: 2%;
    margin-right: 2em;

  }

  /* .elevage-gallery{ 
    display: block;
  } */

  .article-wrapper {
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  .article-img-inline {
    display: block;
    width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .title2 {
    text-align: center;
    font-size: 1.5em;
  }

  .simplecarousel-wrapper {
    height: 190px;
  }

  .carousel-wrapper {
    height: 25vh;
  }

  .bckg-position {
    height: 85em;
  }

  .historique-wrapper {
    display: block
  }

  .presentation-wrapper {
    display: block;

  }


  h2 {
    font-size: 1.2em;
  }

  h1 {
    font-size: 2em;

  }

  .h1-section {
    font-size: 0.8em;
    font-family: 'Julius Sans One', sans-serif;
    color: #A1A27E;
    padding: 1em;
    font-weight: 700;
  }

  .about-h1 {
    font-size: 0.8em;
  }

  .h1-section {
    font-size: 1.5em;
  }

  .title1 {
    text-align: center;
    font-size: 1.6em;
  }

  .theCarousel {
    height: inherit;
  }

  .dev-wrapper {
    display: block;
  }

  .dev-card {
    max-width: 70%;
  }

  .dev-card:nth-child(even) {
    transform: none;
    position: relative;
    top: 5px;
  }

  .card-wrapper {
    margin-top: 5em;
  }

  .wrapper-details {
    display: grid;

    grid-template-columns: none;
    row-gap: 5%;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    position: relative;
    text-align: center;
  }

  .route-section {
    font-size: smaller;

  }

  .portfolio-sections {
    display: grid;
    grid-template-columns: 70% 22%;
    -moz-column-gap: 2%;
         column-gap: 2%;
    margin-right: 2em;

  }

  .portfolio-videos {
    margin: 0 auto;
    display: block;
    width: 100%;
    height: auto;
    margin-top: 1em;
  }

  .embed-videos {
    width: 90%;
    height: 150px;
  }

  .info-grid {
    display: block;
    position: absolute;
    width: 80%;
    margin: 5em auto;
  }

  .relative-div-replace-absolute {
    display: block;
    position: relative;
    height: 22em;

  }

  .compass-div {
    display: grid;
    position: relative;
    margin: 0 auto;
    width: 100%;


  }

  .compass-phone {
    margin-left: 5em;
  }

  .fixed-bckg-00 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/accueil/carriere/carriere_01.jpg");

    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-compo {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230, 0.5);
    /* background-color: #EED7C5; */
    position: fixed;

  }

  .fixed-bckg-01 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background04.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-02 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background07.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-03 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background08.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-04 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background03.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-05 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background02.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-06 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background03.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-07 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background05.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-08 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background06.jpg");

    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-09 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background09.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }
}

/*MINI SCREENS*/
@media only screen and (min-device-width : 100px) and (max-device-width : 320px) {
  .stage-medal-rel{
    width: 60px;
    height:60px;
  }
  .fit-img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer; 
    max-width: 100%;
    height: auto;
    margin: 0 auto; 
    opacity: 1; 
    display: inline-flex;
    flex-wrap: wrap;
    transition: transform .15s linear;
    background-color: var(--appBtn);
    margin: 0 auto;
    border-style: solid;
    border-width: 2px;
    border-color: var(--taupe);
    -webkit-animation: 4s scalepoplight;
            animation: 4s scalepoplight;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    transition-timing-function: cubic-bezier(.42, 0, .58, 1);
    border-radius: 5%;
    
    
  }
  
  .cso-cjt-img{
    -o-object-fit: contain;
       object-fit: contain;
    width: 45%;
    height: auto;
    border-radius: 3%;
    box-shadow: 4px 4px 10px rgb(39 36 2 / 30%);
  }
  .retro-container {
    margin: 0 auto;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: grid;
    position: relative;
    grid-template-columns: 20% 60% 20%;
    max-width: 100vw;
    margin-bottom: 1rem;
  }
  .cso-cjt-img{
    width: 80%;
  }
  .cso-pro-img{
    top:0em;
  }
  .image-stage{
    width: 60%;
  }
  .cross-picture{
    width: inherit;
    height: inherit;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .cross-container{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px) 110px;
    grid-template-rows: -webkit-max-content;
    grid-template-rows: max-content;
  }
  .copyright-container{
    font-size: 1em;   
   }
  .lock-picture{
    width: auto;
    height: 50px;
  }
  .double-embed{
    position: relative;
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 50% 50%;
    width: 100%;
    max-width: 100vw;
    margin: 1rem;
  }
  
  .opendoors-block{
    display: block;
  }
 
  .school-medal-block {
    width: 10em;
    height: 10em;
    top: 65%;
    left: 50%;
  }

  .actu-spectacle {
    margin: 0 auto;
    width: 100%;
    display: block;
    text-align: -webkit-center;

  }


  .actu-blasons {
    margin: 0 auto;
    width: 100%;
  }

  .medaillon {
    width: 0%;
  }


  .photo-block-elt {
    display: block;
    width: 100%;
    height: 80px;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .article-three-cols, .article-three-cols-simple {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: stretch;
    flex-wrap: wrap;

  }
  .article-three-cols{
    margin-top: 1rem;
  }

  .section-wrapper {
    width: 100%;
  }

  .elevage-sections {
    display: block;
    -moz-column-gap: 2%;
         column-gap: 2%;
    margin-right: 2em;

  }

  /* .elevage-gallery{ 
    display: block;
  } */

  .article-wrapper {
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  .article-img-inline {
    display: block;
    width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .title2 {
    text-align: center;
    font-size: 1.5em;
  }

  .carousel-wrapper {
    height: 25vh;
  }

  .bckg-position {
    height: 85em;
  }

  .historique-wrapper {
    display: block
  }

  .presentation-wrapper {
    display: block;

  }


  h2 {
    font-size: 1.2em;
  }

  h1 {
    font-size: 2em;

  }

  .h1-section {
    font-size: 0.8em;
    font-family: 'Julius Sans One', sans-serif;
    color: #A1A27E;
    padding: 1em;
    font-weight: 700;
  }

  .about-h1 {
    font-size: 0.8em;
  }

  .h1-section {
    font-size: 1.5em;
  }

  .title1 {
    text-align: center;
    font-size: 1.6em;
  }

  .theCarousel {
    height: inherit;
  }

  .dev-wrapper {
    display: block;
  }

  .dev-card {
    max-width: 70%;
  }

  .dev-card:nth-child(even) {
    transform: none;
    position: relative;
    top: 5px;
  }

  .card-wrapper {
    margin-top: 5em;
  }

  .wrapper-details {
    display: grid;

    grid-template-columns: none;
    row-gap: 5%;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    position: relative;
    text-align: center;
  }

  .route-section {
    font-size: smaller;

  }

  .portfolio-sections {
    display: grid;
    grid-template-columns: 70% 22%;
    -moz-column-gap: 2%;
         column-gap: 2%;
    margin-right: 2em;

  }

  .portfolio-videos {
    margin: 0 auto;
    display: block;
    width: 100%;
    height: auto;
    margin-top: 1em;
  }

  .embed-videos {
    width: 90%;
    height: 150px;
  }

  .info-grid {
    display: block;
    position: absolute;
    width: 80%;
    margin: 5em auto;
  }

  .relative-div-replace-absolute {
    display: block;
    position: relative;
    height: 22em;

  }

  .compass-div {
    display: grid;
    position: relative;
    margin: 0 auto;
    width: 100%;

  }

  .compass-phone {
    margin-left: 5em;
  }

  .fixed-bckg-00 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/accueil/carriere/carriere_01.jpg");

    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-compo {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230, 0.5);
    /* background-color: #EED7C5; */
    position: fixed;

  }

  .fixed-bckg-01 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background04.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-02 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background07.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-03 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background08.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-04 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background03.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-05 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background02.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-06 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background03.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-07 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background05.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-08 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background06.jpg");

    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-09 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background09.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }
}

/*SMARTPHONES*/
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .stage-medal-rel{
    width: 60px;
    height:60px;
  }
  
  .fit-img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer; 
    max-width: 100%;
    height: auto;
    margin: 0 auto; 
    opacity: 1; 
    display: inline-flex;
    flex-wrap: wrap;
    transition: transform .15s linear;
    background-color: var(--appBtn);
    margin: 0 auto;
    border-style: solid;
    border-width: 2px;
    border-color: var(--taupe);
    -webkit-animation: 4s scalepoplight;
            animation: 4s scalepoplight;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    transition-timing-function: cubic-bezier(.42, 0, .58, 1);
    border-radius: 5%;
    
    
  }
  
  .cso-cjt-img{
    -o-object-fit: contain;
       object-fit: contain;
    width: 45%;
    height: auto;
    border-radius: 3%;
    box-shadow: 4px 4px 10px rgb(39 36 2 / 30%);
  }
  
  .retro-container {
    margin: 0 auto;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: grid;
    position: relative;
    grid-template-columns: 20% 60% 20%;
    max-width: 100vw;
    margin-bottom: 1rem;
  }
  .cso-cjt-img{
    width: 80%;
  }
  .cso-pro-img{
    top:0em;
  }
  
  .image-stage{
    width: 60%;
  }
  .cross-picture{
    width: inherit;
    height: inherit;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .cross-container{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px) 110px;
    grid-template-rows: -webkit-max-content;
    grid-template-rows: max-content;
  }
  
  .copyright-container{
    font-size: 1em;   
   }
  .lock-picture{
    width: auto;
    height: 100px;
  }
  .double-embed{
    position: relative;
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 50% 50%;
    width: 100%;
    max-width: 100vw;
    margin: 1rem;
  }
  
  .opendoors-block{
    display: block;
  }
  
  .school-medal-block {
    width: 10em;
    height: 10em;
    top: 65%;
    left: 50%;

  }

  .actu-spectacle {
    margin: 0 auto;
    width: 100%;
    display: block;
    text-align: -webkit-center;

  }

  .actu-blasons {
    margin: 0 auto;
    width: 100%;
  }

  .medaillon {
    width: 0%;
  }

  .photo-block-elt {
    display: block;
    width: 100%;
    height: 80px;
    -o-object-fit: contain;
       object-fit: contain;
  }


  .article-three-cols, .article-three-cols-simple {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: stretch;
    flex-wrap: wrap;

  }
  .article-three-cols{
    margin-top: 1rem;
  }

  .section-wrapper {
    width: 100%;
  }

  .elevage-sections {
    display: block;
    -moz-column-gap: 2%;
         column-gap: 2%;
    margin-right: 2em;

  }

  /* .elevage-gallery{ 
    display: block;
  } */

  .article-wrapper {
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  .article-img-inline {
    display: block;
    width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .title2 {
    text-align: center;
    font-size: 1.5em;
  }

  .simplecarousel-wrapper {
    height: 190px;
  }

  .img-simple-carousel {
    -o-object-fit: contain;
       object-fit: contain;
  }

  .carousel-wrapper {
    height: 25vh;
  }

  .bckg-position {
    height: 85em;
  }

  .historique-wrapper {
    display: block
  }

  .presentation-wrapper {
    display: block;

  }


  h2 {
    font-size: 1.2em;
  }

  h1 {
    font-size: 2em;

  }

  .h1-section {
    font-size: 0.8em;
    font-family: 'Julius Sans One', sans-serif;
    color: #A1A27E;
    padding: 1em;
    font-weight: 700;
  }

  .about-h1 {
    font-size: 0.8em;
  }

  .h1-section {
    font-size: 1.5em;
  }

  .title1 {
    text-align: center;
    font-size: 1.6em;
  }

  .theCarousel {
    height: inherit;
  }

  .dev-wrapper {
    display: block;
  }

  .dev-card {
    max-width: 70%;
  }

  .dev-card:nth-child(even) {
    transform: none;
    position: relative;
    top: 5px;
  }

  .card-wrapper {
    margin-top: 5em;
  }

  .wrapper-details {
    display: grid;

    grid-template-columns: none;
    row-gap: 5%;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    position: relative;
    text-align: center;
  }

  .route-section {
    font-size: smaller;

  }

  .portfolio-sections {
    display: grid;
    grid-template-columns: 70% 22%;
    -moz-column-gap: 2%;
         column-gap: 2%;
    margin-right: 2em;

  }

  .portfolio-videos {
    margin: 0 auto;
    display: block;
    width: 100%;
    height: auto;
    margin-top: 1em;
  }

  .embed-videos {
    width: 90%;
    height: 150px;
  }

  .info-grid {
    display: block;
    position: absolute;
    width: 80%;
    margin: 5em auto;
  }

  .relative-div-replace-absolute {
    display: block;
    position: relative;
    height: 22em;

  }

  .compass-div {
    display: grid;
    position: relative;
    margin: 0 auto;
    width: 100%;


  }

  .compass-phone {
    margin-left: 5em;
  }

  .fixed-bckg-00 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/accueil/carriere/carriere_01.jpg");

    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-compo {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230, 0.5);
    /* background-color: #EED7C5; */
    position: fixed;

  }

  .fixed-bckg-01 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background04.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-02 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background07.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-03 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background08.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-04 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background03.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-05 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background02.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-06 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background03.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-07 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background05.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-08 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background06.jpg");

    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-09 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background09.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }
}

@media screen and (min-device-width : 800px) and (max-device-width : 1280px) {
  .stage-medal-rel{
    width: 60px;
    height:60px;
  }
  
  .fit-img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer; 
    max-width: 100%;
    height: auto;
    margin: 0 auto; 
    opacity: 1; 
    display: inline-flex;
    flex-wrap: wrap;
    transition: transform .15s linear;
    background-color: var(--appBtn);
    margin: 0 auto;
    border-style: solid;
    border-width: 2px;
    border-color: var(--taupe);
    -webkit-animation: 4s scalepoplight;
            animation: 4s scalepoplight;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    transition-timing-function: cubic-bezier(.42, 0, .58, 1);
    border-radius: 5%;
    
    
  }
 
  .cso-cjt-img{
    -o-object-fit: contain;
       object-fit: contain;
    width: 45%;
    height: auto;
    border-radius: 3%;
    box-shadow: 4px 4px 10px rgb(39 36 2 / 30%);
  }
  
  .retro-container {
    margin: 0 auto;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: grid;
    position: relative;
    grid-template-columns: 20% 60% 20%;
    max-width: 100vw;
    margin-bottom: 1rem;
  }
  .cso-cjt-img{
    width: 80%;
  }
  .cso-pro-img{
    top:0em;
  }
  
  .image-stage{
    width: 60%;
  }
  .cross-picture{
    width: inherit;
    height: inherit;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .cross-container{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px) 110px;
    grid-template-rows: -webkit-max-content;
    grid-template-rows: max-content;
  }
  
  .copyright-container{
    font-size: 1em;   
   }
  .lock-picture{
    width: auto;
    height: 100px;
  }
  .double-embed{
    position: relative;
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 50% 50%;
    width: 100%;
    max-width: 100vw;
    margin: 1rem;
  }
  
  .opendoors-block{
    display: block;
  }
  
  .school-medal-block {
    width: 15em;
    height: 15em;
    top: 60%;
    left: 65%;
  }

  .actu-blasons {
    margin: 0 auto;
    width: 100%;
  }


  .medaillon {
    width: 0%;
  }

  .photo-block-elt {
    display: block;
    width: 100%;
    height: 80px;
    -o-object-fit: contain;
       object-fit: contain;
  }


  .article-three-cols, .article-three-cols-simple {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: stretch;
    flex-wrap: wrap;

  }
  .article-three-cols{
    margin-top: 1rem;
  }

  .section-wrapper {
    width: 100%;
  }

  .elevage-sections {
    display: block;
    -moz-column-gap: 2%;
         column-gap: 2%;
    margin-right: 2em;

  }


  /* .elevage-gallery{ 
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    column-gap: 1em;
   
  } */

  .article-wrapper {
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  .article-img-inline {
    display: block;
    width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .title2 {
    text-align: center;
    font-size: 1.5em;
  }

  .carousel-wrapper {
    height: 75vh;
  }

  .simplecarousel-wrapper {
    display: block;
    margin-bottom: 5rem;
    width: 100%;
  }

  .img-carousel {
    width: 100%;
    height: auto;
  }

  .bckg-position {
    height: 85em;
  }

  .historique-wrapper {
    display: block
  }

  .presentation-wrapper {
    display: block;

  }


  h2 {
    font-size: 1.2em;
  }

  h1 {
    font-size: 2em;

  }

  .h1-section {
    font-size: 0.8em;
    font-family: 'Julius Sans One', sans-serif;
    color: #A1A27E;
    padding: 1em;
    font-weight: 700;
  }

  .about-h1 {
    font-size: 0.8em;
  }

  .h1-section {
    font-size: 1.5em;
  }

  .title1 {
    text-align: center;
    font-size: 1.6em;
  }

  .theCarousel {
    height: inherit;
  }

  .dev-wrapper {
    display: block;
  }

  .dev-card {
    max-width: 70%;
  }

  .dev-card:nth-child(even) {
    transform: none;
    position: relative;
    top: 5px;
  }

  .card-wrapper {
    margin-top: 5em;
  }

  .wrapper-details {
    display: grid;

    grid-template-columns: none;
    row-gap: 5%;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    position: relative;
    text-align: center;
  }

  .route-section {
    font-size: smaller;


  }

  .portfolio-sections {
    display: grid;
    grid-template-columns: 70% 22%;
    -moz-column-gap: 2%;
         column-gap: 2%;
    margin-right: 2em;

  }

  .portfolio-videos {
    margin: 0 auto;
    display: block;
    width: 100%;
    height: auto;
    margin-top: 1em;
  }

  .embed-videos {
    width: 90%;
    height: 350px;
  }

  .info-grid {
    display: block;
    position: absolute;
    width: 80%;
    margin: 5em auto;
  }

  .relative-div-replace-absolute {
    display: block;
    position: relative;
    height: 22em;

  }

  .compass-div {
    display: grid;
    position: relative;
    margin: 0 auto;
    width: 100%;


  }

  .compass-phone {
    margin-left: 5em;
  }

  .fixed-bckg-00 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/accueil/carriere/carriere_01.jpg");

    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-compo {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230, 0.5);
    /* background-color: #EED7C5; */
    position: fixed;

  }

  .fixed-bckg-01 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background04.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-02 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background07.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-03 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background08.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-04 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background03.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-05 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background02.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-06 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background03.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-07 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background05.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-08 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background06.jpg");

    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-09 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background09.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

}

@media screen and (min-device-width : 480px) and (max-device-width : 992px) {
  .stage-medal-rel{
    width: 60px;
    height:60px;
  }
  
  .fit-img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer; 
    max-width: 100%;
    height: auto;
    margin: 0 auto; 
    opacity: 1; 
    display: inline-flex;
    flex-wrap: wrap;
    transition: transform .15s linear;
    background-color: var(--appBtn);
    margin: 0 auto;
    border-style: solid;
    border-width: 2px;
    border-color: var(--taupe);
    -webkit-animation: 4s scalepoplight;
            animation: 4s scalepoplight;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    transition-timing-function: cubic-bezier(.42, 0, .58, 1);
    border-radius: 5%;    
  }
  
  .cso-cjt-img{
    -o-object-fit: contain;
       object-fit: contain;
    width: 45%;
    height: auto;
    border-radius: 3%;
    box-shadow: 4px 4px 10px rgb(39 36 2 / 30%);
  }
  
  .retro-container {
    margin: 0 auto;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: grid;
    position: relative;
    grid-template-columns: 20% 60% 20%;
    max-width: 100vw;
    margin-bottom: 1rem;
  }
  .cso-cjt-img{
    width: 80%;
  }
  .cso-pro-img{
    top:0em;
  }
  
  .image-stage{
    width: 60%;
  }
  .cross-picture{
    width: inherit;
    height: inherit;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .cross-container{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px) 110px;
    grid-template-rows: -webkit-max-content;
    grid-template-rows: max-content;
  }
  
  .copyright-container{
    font-size: 1em;   
   }
  .lock-picture{
    width: auto;
    height: 100px;
  }
  .double-embed{
    position: relative;
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 50% 50%;
    width: 100%;
    max-width: 100vw;
    margin: 1rem;
  }
  .embed{
    width: 100%;
    height: 500px;
  }
  
  .opendoors-block{
    display: block;
  }
  
  .school-medal-block {
    width: 15em;
    height: 15em;
    top: 60%;
    left: 65%;
  }

  .actu-blasons {
    margin: 0 auto;
    width: 100%;
  }

  .medaillon {
    width: 0%;
  }


  .photo-block-elt {
    display: block;
    width: 100%;
    height: 190px;
    -o-object-fit: contain;
       object-fit: contain;
  }


  .article-three-cols, .article-three-cols-simple {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: stretch;
    flex-wrap: wrap;
   
  }
.article-three-cols{
  margin-top: 1rem;
}
  .section-wrapper {
    width: 100%;
  }

  .elevage-sections {
    display: block;
    -moz-column-gap: 2%;
         column-gap: 2%;
    margin-right: 2em;

  }

  .article-wrapper {
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  .article-img-inline {
    display: block;
    width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }



  .title2 {
    text-align: center;
    font-size: 1.5em;
  }

  .carousel-wrapper {
    height: 60vh;
  }

  .simplecarousel-wrapper {
    display: block;
    height: 60vh;
    margin-bottom: 3rem;
    width: 100%;
  }

  .img-carousel {
    width: 100%;
    height: auto;
  }

  .bckg-position {
    height: 85em;
  }

  .historique-wrapper {
    display: block
  }

  .presentation-wrapper {
    display: block;

  }


  h2 {
    font-size: 1.2em;
  }

  h1 {
    font-size: 2em;

  }

  .h1-section {
    font-size: 0.8em;
    font-family: 'Julius Sans One', sans-serif;
    color: #A1A27E;
    padding: 1em;
    font-weight: 700;
  }

  .about-h1 {
    font-size: 0.8em;
  }

  .h1-section {
    font-size: 1.5em;
  }

  .title1 {
    text-align: center;
    font-size: 1.6em;
  }

  .theCarousel {
    height: inherit;
  }

  .dev-wrapper {
    display: block;
  }

  .dev-card {
    max-width: 70%;
  }

  .dev-card:nth-child(even) {
    transform: none;
    position: relative;
    top: 5px;
  }

  .card-wrapper {
    margin-top: 5em;
  }

  .wrapper-details {
    display: grid;

    grid-template-columns: none;
    row-gap: 5%;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    position: relative;
    text-align: center;
  }

  .route-section {
    font-size: smaller;

  }

  .portfolio-sections {
    display: grid;
    grid-template-columns: 70% 22%;
    -moz-column-gap: 2%;
         column-gap: 2%;
    margin-right: 2em;

  }

  .portfolio-videos {
    margin: 0 auto;
    display: block;
    width: 100%;
    height: auto;
    margin-top: 1em;
  }

  .embed-videos {
    width: 90%;
    height: 350px;
  }

  .info-grid {
    display: block;
    position: absolute;
    width: 80%;
    margin: 5em auto;
  }

  .relative-div-replace-absolute {
    display: block;
    position: relative;
    height: 22em;

  }

  .compass-div {
    display: grid;
    position: relative;
    margin: 0 auto;
    width: 100%;


  }

  .compass-phone {
    margin-left: 5em;
  }

  .fixed-bckg-00 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/accueil/carriere/carriere_01.jpg");

    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-compo {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230, 0.5);
    /* background-color: #EED7C5; */
    position: fixed;

  }

  .fixed-bckg-01 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background04.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 1;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-02 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background07.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-03 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background08.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-04 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background03.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-05 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background02.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-06 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background03.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-07 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background05.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-08 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background06.jpg");

    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

  .fixed-bckg-09 {
    width: 100%;
    height: 100vh;
    background-image: url("../images/background09.jpg");
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    opacity: 0.8;
    margin: 0 auto;
    background-color: rgba(245, 239, 230);
    /* background-color: #EED7C5; */
    background-attachment: fixed;
  }

}
