.training-full{
    display: flex;
    position: relative;
  
}
.training-photo-title{
  display: block;
  width: 100%;
  max-width: 100vw;
  height: 200px;
  object-fit:cover;
}
.training-photo{
    width: 100%;
    height: 100%;
    object-fit:cover;
    margin: 0 auto;

  }
  .photo-title-actus{
    width: 6em;
    max-width: 100%;
    
  }
  .title-block{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  
  